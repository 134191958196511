define("tortuga-frontend/templates/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hdgBIZ/k",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\"],[11,\"class\",\"hero is-fullheight\"],[9],[0,\"\\n  \"],[5,\"header\",[],[[],[]]],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"index-container\"],[9],[0,\"\\n    \"],[5,\"message\",[],[[\"@title\",\"@body\",\"@context\"],[\"Ajajaj...\",\"Zdá se, že nám teď nějak teče do lodi... spravujem a záplatujem, ale nezbývá, než se u nás zastavit osobně a objednat si jako za starých časů.\",\"danger\"]]],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"footer\"],[11,\"class\",\"footer\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"content has-text-centered\"],[9],[0,\"\\n      \"],[7,\"p\"],[9],[0,\"\\n        \"],[7,\"strong\"],[9],[0,\"© 2019 Tortuga Bay - Bar & Grill\"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tortuga-frontend/templates/error.hbs"
    }
  });

  _exports.default = _default;
});
define("tortuga-frontend/instance-initializers/customer-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(appInstance) {
    const customerManager = appInstance.lookup('service:customer-manager');
    customerManager.initCustomerFromLocalStorage();
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});
define("tortuga-frontend/services/websocket", ["exports", "tortuga-frontend/config/environment", "pusher-js"], function (_exports, _environment, _pusherJs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and set to use loose mode. ' + 'To use proposal-class-properties in spec mode with decorators, wait for ' + 'the next major version of decorators in stage 2.'); }

  let WebSocketService = (_class = (_temp = class WebSocketService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "appLogger", _descriptor, this);

      this.isOnline = false;
      this.client = null;
    }

    connect() {
      const pusher = new _pusherJs.default(_environment.default.pusher.appKey, {
        cluster: _environment.default.pusher.appCluster,
        forceTLS: true
      });
      pusher.connection.bind('error', err => {
        if (err.error.data.code === 4004) {
          this.appLogger.log('Pusher connection limit error', err);
          return;
        }
      });
      pusher.connection.bind('state_change', states => {
        this.set('isOnline', states.current === 'connected');
      });
      this.client = pusher;
    }

    subscribe(channelName, handlers = []) {
      const channel = this.client.subscribe(channelName);
      channel.bind('pusher:subscription_error', status => {
        this.appLogger.log(`Pusher subscription error to channel: ${channelName}`, status);
      });
      handlers.forEach(handler => {
        channel.bind(handler.eventName, handler.eventHandler);
      });
    }

    unsubscribe(channelName) {
      this.client.unsubscribe(channelName);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "appLogger", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = WebSocketService;
});
define("tortuga-frontend/events/order-attempted", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class OrderAttemptedEvent {
    constructor(value = 0) {
      this.category = 'order';
      this.action = 'purchase_attempt';
      this.label = '';
      this.value = parseInt(value, 10);
    }

  }

  _exports.default = OrderAttemptedEvent;
});
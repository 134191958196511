define("tortuga-frontend/controllers/confirmation", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and set to use loose mode. ' + 'To use proposal-class-properties in spec mode with decorators, wait for ' + 'the next major version of decorators in stage 2.'); }

  let ConfirmationController = (_dec = Ember.computed('orderState.order_time'), (_class = (_temp = class ConfirmationController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "orderState", _descriptor2, this);

      this.currentTab = 'tabConfirmation';
    }

    get orderTimeFormatted() {
      return (0, _moment.default)(this.orderState.order_time).format('HH:mm');
    }

    reset() {
      this.orderState.resetOrder();
      this.router.transitionTo('index');
    }

    doNothing() {//
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "orderState", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "orderTimeFormatted", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "orderTimeFormatted"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "reset", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "reset"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "doNothing", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "doNothing"), _class.prototype)), _class));
  _exports.default = ConfirmationController;
});
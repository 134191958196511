define("tortuga-frontend/services/js-loader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class JsLoaderService extends Ember.Service {
    constructor(...args) {
      super(...args);
      this._cache = Ember.A();
    }

    _isCached(src) {
      return this._cache.includes(src);
    }

    _updateCache(src) {
      this._cache.pushObject(src);
    }

    _getScriptAttr(attr) {
      return Object.assign({
        type: 'text/javascript'
      }, attr);
    }

    _loadJs(attr) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        const attrs = this._getScriptAttr(attr);

        let script = Object.assign(document.createElement('script'), attrs);
        script.addEventListener('load', () => {
          resolve();
        }, false);
        script.addEventListener('error', reason => {
          reject(reason);
        }, false);
        document.getElementsByTagName('body')[0].appendChild(script);
      });
    }

    _isValidAttr(attr) {
      return !Ember.isEmpty(attr) && !Ember.isEmpty(attr.src) && !Ember.isBlank(attr.src);
    }

    load(attr) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        if (!this._isValidAttr(attr)) {
          resolve();
          return;
        }

        if (this._isCached(attr.src)) {
          resolve();
          return;
        }

        this._loadJs(attr).then(() => {
          this._updateCache(attr.src);

          resolve();
        }, reason => {
          reject(reason);
        });
      });
    }

  }

  _exports.default = JsLoaderService;
});
define("tortuga-frontend/adapters/application", ["exports", "ember-data", "tortuga-frontend/config/environment"], function (_exports, _emberData, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONAPIAdapter.extend({
    host: _environment.default.api.host,
    namespace: 'api'
  });

  _exports.default = _default;
});
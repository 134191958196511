define("tortuga-frontend/validations/name-validation", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: [(0, _validators.validatePresence)({
      presence: true,
      message: 'Vyplňte Vaše jméno.'
    })]
  };
  _exports.default = _default;
});
define("tortuga-frontend/components/form/form-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and set to use loose mode. ' + 'To use proposal-class-properties in spec mode with decorators, wait for ' + 'the next major version of decorators in stage 2.'); }

  let FormInputComponent = (_dec = Ember.computed.alias('type'), _dec2 = Ember.computed('name', 'changeset.change', 'hasError'), _dec3 = Ember.computed('name', 'changeset.error'), _dec4 = Ember.computed('name', 'changeset.error'), (_class = (_temp = class FormInputComponent extends Ember.Component {
    constructor(...args) {
      super(...args);
      this.classNames = ['field', 'is-horizontal'];
      this.placeholder = '';
      this.label = '';
      this.type = 'text';

      _initializerDefineProperty(this, "inputType", _descriptor, this);

      this.disabled = false;
    }

    get isValid() {
      return this.get(`changeset.change.${this.get('name')}`) && !this.get('hasError');
    }

    get hasError() {
      return this.get(`changeset.error.${this.get('name')}.value`);
    }

    get errorMessage() {
      return this.get(`changeset.error.${this.get('name')}.validation`);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "inputType", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "isValid", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "isValid"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "hasError", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "hasError"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "errorMessage", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "errorMessage"), _class.prototype)), _class));
  _exports.default = FormInputComponent;
});
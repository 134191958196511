define("tortuga-frontend/components/account-kit-login", ["exports", "tortuga-frontend/events/customer-verification-attempted"], function (_exports, _customerVerificationAttempted) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and set to use loose mode. ' + 'To use proposal-class-properties in spec mode with decorators, wait for ' + 'the next major version of decorators in stage 2.'); }

  let AccountKitLoginComponent = (_class = (_temp = class AccountKitLoginComponent extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "accountKit", _descriptor, this);

      _initializerDefineProperty(this, "appLogger", _descriptor2, this);

      _initializerDefineProperty(this, "appLogger", _descriptor3, this);

      this.classNames = ['account-kit-login'];
      this.disabled = false;
      this.countryCode = '+420';
      this.mobileNumber = '';
      this.email = '';
    }

    onFinish() {} //
    // TODO: load this from localStorage if there has been previous order
    // TODO: this should get set by locale


    smsLogin() {
      this.appLogger.reportToAnalytics(new _customerVerificationAttempted.default('mobile'));
      this.accountKit.loginViaMobile(this.countryCode, this.mobileNumber).then(code => {
        this.onFinish('mobile', code);
      }, reason => {
        this.appLogger.error(new Error(`AccountKit SMS login error: ${reason}`), true);
        return;
      });
    }

    emailLogin() {
      this.accountKit.loginViaEmail(this.email).then(code => {
        this.onFinish('email', code);
      }, reason => {
        this.appLogger.error(new Error(`AccountKit email login error: ${reason}`), true);
        return;
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "accountKit", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "appLogger", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "appLogger", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "smsLogin", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "smsLogin"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "emailLogin", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "emailLogin"), _class.prototype)), _class);
  _exports.default = AccountKitLoginComponent;
});
define("tortuga-frontend/templates/components/product-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "z7t2Cfpr",
    "block": "{\"symbols\":[\"product\"],\"statements\":[[4,\"each\",[[25,[\"products\"]]],null,{\"statements\":[[0,\"  \"],[5,\"product-card\",[],[[\"@product\",\"@hideHeading\"],[[24,1,[]],[23,\"hasOnlySingleProduct\"]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tortuga-frontend/templates/components/product-list.hbs"
    }
  });

  _exports.default = _default;
});
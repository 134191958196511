define("tortuga-frontend/models/cart-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class CartItem {
    constructor(productVariationId, quantity = 1, sequence = 1) {
      this.productVariationId = '' + productVariationId;
      this.quantity = parseInt(quantity, 10);
      this.sequence = parseInt(sequence, 10);
      Object.freeze(this);
    }

  }

  var _default = CartItem;
  _exports.default = _default;
});
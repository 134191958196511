define("tortuga-frontend/services/products", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ProductsService extends Ember.Service {
    constructor(...args) {
      super(...args);
      this.products = [];
    }

    setProducts(products) {
      this.set('products', products);
    }

  }

  _exports.default = ProductsService;
});
define("tortuga-frontend/models/variation", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    product: belongsTo('product'),
    title: attr('string'),
    slug: attr('string'),
    description: attr('string'),
    price: attr('number'),
    formatted_price: attr('string'),
    formattedPrice: Ember.computed.alias('formatted_price')
  });

  _exports.default = _default;
});
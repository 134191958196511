define("tortuga-frontend/services/slots", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and set to use loose mode. ' + 'To use proposal-class-properties in spec mode with decorators, wait for ' + 'the next major version of decorators in stage 2.'); }

  let SlotsService = (_class = (_temp = class SlotsService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "flashMessages", _descriptor, this);

      _initializerDefineProperty(this, "kitchenState", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      this.slots = [];
    }

    setSlots(slots) {
      this.set('slots', slots);
    }

    reloadSlots() {
      this.store.unloadAll('slot');
      return this.store.findAll('slot', {
        reload: true
      }).then(slots => {
        this.setSlots(slots);

        if (!slots.length) {
          this._noSlotsErrorHandling();
        }

        return slots;
      }, () => {
        this._noSlotsErrorHandling();
      });
    }

    findSlot(slotId) {
      const foundSlots = this.slots.filter(slot => slot.id === slotId);
      return foundSlots.length ? foundSlots[0] : null;
    }

    _noSlotsErrorHandling() {
      this.flashMessages.danger(`Zdá se, že teď nepřijímáme objednávky. Buď máme zavřeno, anebo plné ruce práce. Zkuste to později anebo se u nás zastavte osobně a objednejte si jako za starých časů.`);
      this.kitchenState.closeShop();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "flashMessages", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "kitchenState", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = SlotsService;
});
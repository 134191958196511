define("tortuga-frontend/templates/components/facebook-login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "s3F7p2G+",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\"],[11,\"class\",\"facebook-login__button\"],[12,\"disabled\",[23,\"disabled\"]],[9],[0,\"\\n  \"],[7,\"i\"],[11,\"class\",\"fab fa-facebook-f\"],[9],[10],[0,\"\\n  Prihlasit se pres Facebook\\n\"],[3,\"action\",[[24,0,[]],\"login\"],[[\"on\"],[\"click\"]]],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tortuga-frontend/templates/components/facebook-login.hbs"
    }
  });

  _exports.default = _default;
});
define("tortuga-frontend/services/account-kit", ["exports", "tortuga-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and set to use loose mode. ' + 'To use proposal-class-properties in spec mode with decorators, wait for ' + 'the next major version of decorators in stage 2.'); }

  let AccountKitService = (_class = (_temp = class AccountKitService extends Ember.Service {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "appLogger", _descriptor, this);

      _initializerDefineProperty(this, "jsLoader", _descriptor2, this);

      _initializerDefineProperty(this, "orderState", _descriptor3, this);

      _initializerDefineProperty(this, "store", _descriptor4, this);

      if (_environment.default.environment === 'test') {
        return;
      }

      this.state = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15); // first set up the hook

      window.AccountKit_OnInteractive = () => {
        AccountKit.init({
          appId: _environment.default.accountKit.appId,
          version: _environment.default.accountKit.version,
          redirect: _environment.default.accountKit.redirectUrl,
          debug: _environment.default.accountKit.debug,
          state: this.state,
          fbAppEventsEnabled: true
        });
      }; // then load the SDK


      this.jsLoader.load({
        src: 'https://sdk.accountkit.com/cs_CZ/sdk.js',
        crossorigin: 'anonymous'
      }).catch(error => {
        this.appLogger.error(error, true);
      });
    }
    /**
     * AccountKit login via mobile number
     * @param {string} countryCode with plus sign, e.g. +420
     * @param {string} mobileNumber national number, e.g. 731222333
     */


    loginViaMobile(countryCode, mobileNumber) {
      return this._login('PHONE', {
        countryCode: countryCode,
        phoneNumber: mobileNumber
      });
    }
    /**
     * AccountKit login via email
     * @param {string} email
     */


    loginViaEmail(email) {
      return this._login('EMAIL', {
        emailAddress: email
      });
    }
    /**
     * AccountKit login, for details see
     * https://developers.facebook.com/docs/accountkit/webjs/reference/
     * @param {string} method PHONE|EMAIL
     * @param {object} data
     */


    _login(method, data) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        AccountKit.login(method, data, response => {
          if (response.status !== 'PARTIALLY_AUTHENTICATED') {
            return reject(response.status);
          }

          if (response.state !== this.state) {
            return reject('Invalid request state.');
          }

          resolve(response.code);
        });
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "appLogger", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "jsLoader", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "orderState", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "store", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = AccountKitService;
});
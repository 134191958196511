define("tortuga-frontend/models/customer", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    reg_type: attr('string'),
    facebook_id: attr('string'),
    account_kit_id: attr('string'),
    code: attr('string'),
    name: attr('string'),
    email: attr('string'),
    mobile_country_prefix: attr('string'),
    mobile_national_number: attr('string'),
    mobile_number: attr('string'),
    created_at: attr('date'),
    updated_at: attr('date'),
    displayRegistrationType: Ember.computed('reg_type', function () {
      switch (this.reg_type) {
        case 'facebook':
          return 'Facebook';

        case 'mobile':
          return 'telefon';

        case 'email':
          return 'email';
      }
    }),
    isFacebookLoginCustomer: Ember.computed('reg_type', function () {
      return this.reg_type === 'facebook';
    }),
    isMobileCustomer: Ember.computed('reg_type', function () {
      return this.reg_type === 'mobile';
    })
  });

  _exports.default = _default;
});
define("tortuga-frontend/routes/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and set to use loose mode. ' + 'To use proposal-class-properties in spec mode with decorators, wait for ' + 'the next major version of decorators in stage 2.'); }

  let IndexRoute = (_class = (_temp = class IndexRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "flashMessages", _descriptor, this);

      _initializerDefineProperty(this, "kitchenState", _descriptor2, this);

      _initializerDefineProperty(this, "orderState", _descriptor3, this);

      _initializerDefineProperty(this, "products", _descriptor4, this);

      _initializerDefineProperty(this, "slots", _descriptor5, this);

      _initializerDefineProperty(this, "websocket", _descriptor6, this);
    }

    beforeModel() {
      super.beforeModel(...arguments);
      this.websocket.connect();
      this.orderState.resetOrder();
    }

    model() {
      return Ember.RSVP.hash({
        categories: this.store.findAll('category'),
        products: this.store.findAll('product', {
          include: 'variations'
        }),
        slots: this.store.findAll('slot')
      });
    }

    afterModel(model) {
      super.afterModel(...arguments);
      this.products.setProducts(model.products);
      this.slots.setSlots(model.slots);

      this._susbcribeToKitchen();

      if (!model.slots.length) {
        this.kitchenState.closeShop();
      }
    }

    resetController(controller) {
      super.resetController(...arguments);
      controller.set('currentTab', 'tabMenu');
    }
    /**
     * Listen on socket `kitchen.opened` events that open the shop
     * Don't close the shop on purpose (might be unnecessarily puttting
     * off a customer)
     */


    _susbcribeToKitchen() {
      this.websocket.subscribe('kitchen', [{
        eventName: 'kitchen.opened',
        eventHandler: () => {
          this.slots.reloadSlots().then(slots => {
            if (slots && slots.length) {
              this.kitchenState.openShop();
              this.flashMessages.clearMessages();
            }
          });
        }
      }]);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "flashMessages", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "kitchenState", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "orderState", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "products", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "slots", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "websocket", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = IndexRoute;
});
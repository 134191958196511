define("tortuga-frontend/storages/cart-items", ["exports", "ember-local-storage/session/array"], function (_exports, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const Storage = _array.default.extend(); // Storage.reopenClass({
  //     initialState() {
  //         return [];
  //     },
  // });


  var _default = Storage;
  _exports.default = _default;
});
define("tortuga-frontend/models/product", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    hasMany
  } = _emberData.default;

  var _default = Model.extend({
    variations: hasMany('variation'),
    category_id: attr('string'),
    title: attr('string'),
    slug: attr('string'),
    description: attr('string'),
    created_at: attr('date'),
    updated_at: attr('date')
  });

  _exports.default = _default;
});
define("tortuga-frontend/components/sticky", ["exports", "stickyfilljs/dist/stickyfill.es6"], function (_exports, _stickyfill) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class StickyComponent extends Ember.Component {
    constructor(...args) {
      super(...args);
      this.classNames = ['sticky'];
      this.classNameBindings = ['_isStuck:sticky--stuck'];
      this._scrollListener = null;
      this._intersectionObserver = null;
      this._isStuck = false;
    }

    didInsertElement() {
      super.didInsertElement(...arguments);
      let options = {
        threshold: 0.5
      };
      this._intersectionObserver = new IntersectionObserver(this._setupScrollListener.bind(this), options); // eslint-disable-next-line

      this._intersectionObserver.observe(this.element); // polyfill for older browsers


      _stickyfill.default.addOne(this.element);
    }

    willDestroyElement() {
      // remove polyfill instance
      _stickyfill.default.removeOne(this.element); // cleanup of scrolling listener


      if (this._scrollListener) {
        window.removeEventListener('scroll', this._scrollListener);
        this._scrollListener = null;
      } // cleanup of the observer


      if (this._intersectionObserver) {
        this._intersectionObserver.disconnect();

        this._intersectionObserver = null;
      }

      super.willDestroyElement(...arguments);
    }

    _setupScrollListener(entries) {
      // element in viewport -> attach listeners, init toggle
      if (entries[0].isIntersecting) {
        this._scrollListener = this._toggleClassNames.bind(this);

        this._toggleClassNames();

        window.addEventListener('scroll', this._scrollListener);
        return;
      } // element out, remove those listeners + unstuck


      window.removeEventListener('scroll', this._scrollListener);
      this._scrollListener = null;
      this.set('_isStuck', false);
    }

    _toggleClassNames() {
      var rect = this.element.getBoundingClientRect(); // not at the top yet/again

      if (rect.top > 1) {
        // remove class if there is still one
        if (this._isStuck) {
          this.set('_isStuck', false);
        }

        return;
      } // add class if there isn't one yet


      if (!this._isStuck) {
        this.set('_isStuck', true);
      }
    }

  }

  _exports.default = StickyComponent;
});
define("tortuga-frontend/instance-initializers/account-kit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(appInstance) {
    return appInstance.lookup('service:account-kit');
  }

  var _default = {
    name: 'account-kit',
    initialize
  };
  _exports.default = _default;
});
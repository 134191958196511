define("tortuga-frontend/serializers/order", ["exports", "tortuga-frontend/serializers/application", "ember-data-save-relationships"], function (_exports, _application, _emberDataSaveRelationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_emberDataSaveRelationships.default, {
    attrs: {
      items: {
        serialize: true
      },
      customer: {
        serialize: true
      }
    }
  });

  _exports.default = _default;
});
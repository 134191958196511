define("tortuga-frontend/services/cart", ["exports", "ember-local-storage", "tortuga-frontend/models/cart-item"], function (_exports, _emberLocalStorage, _cartItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and set to use loose mode. ' + 'To use proposal-class-properties in spec mode with decorators, wait for ' + 'the next major version of decorators in stage 2.'); }

  let CartService = (_dec = (0, _emberLocalStorage.storageFor)('cart-items'), _dec2 = Ember.computed('cartItems.[]'), _dec3 = Ember.computed('cartItems.[]'), _dec4 = Ember.computed('items.[]'), (_class = (_temp = class CartService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "cartItems", _descriptor, this);
    }

    get items() {
      return this.cartItems;
    }

    get orderedItems() {
      return this.cartItems.sortBy('sequence');
    }

    get totalQuantity() {
      return this.items.reduce((acc, item) => {
        acc += item.quantity;
        return acc;
      }, 0);
    }

    addToCart(variationId) {
      const cartItem = this._findInCart(variationId); // first of its kind


      if (!cartItem) {
        return this.cartItems.addObject(new _cartItem.default(variationId, 1, this._getLastSequence() + 1));
      }

      const newCartItem = new _cartItem.default(cartItem.productVariationId, cartItem.quantity + 1, cartItem.sequence);
      this.cartItems.removeObject(cartItem);
      this.cartItems.addObject(newCartItem);
    }

    removeFromCart(variationId) {
      const cartItem = this._findInCart(variationId);

      if (!cartItem) {
        return;
      }

      const newCartItem = new _cartItem.default(cartItem.productVariationId, cartItem.quantity - 1, cartItem.sequence);
      this.cartItems.removeObject(cartItem);

      if (newCartItem.quantity > 0) {
        this.cartItems.addObject(newCartItem);
      }
    }

    resetCart() {
      this.cartItems.clear();
    }

    howMuchOf(variationId) {
      const variation = this._findInCart(variationId);

      return variation ? variation.quantity : 0;
    }

    _findInCart(variationId) {
      const found = this.items.filter(item => item.productVariationId === variationId);

      if (!found.length) {
        return null;
      }

      return found[0];
    }

    _getLastSequence() {
      return this.orderedItems.length ? this.orderedItems.get('lastObject.sequence') : 0;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "cartItems", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "items", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "items"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "orderedItems", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "orderedItems"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "totalQuantity", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "totalQuantity"), _class.prototype)), _class));
  _exports.default = CartService;
});
define("tortuga-frontend/services/app-logger", ["exports", "@sentry/browser"], function (_exports, _browser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and set to use loose mode. ' + 'To use proposal-class-properties in spec mode with decorators, wait for ' + 'the next major version of decorators in stage 2.'); }

  let AppLoggerService = (_class = (_temp = class AppLoggerService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "metrics", _descriptor, this);
    }

    error(error, fatal = false, extras = null) {
      if (fatal) {
        (0, _browser.withScope)(scope => {
          scope.setLevel('fatal');

          if (extras) {
            scope.setExtra('extras', extras);
          }

          (0, _browser.captureException)(error);
        });
        return;
      }

      (0, _browser.withScope)(scope => {
        if (extras) {
          scope.setExtra('extras', extras);
        }

        (0, _browser.captureException)(error);
      });
    }

    log(message, extras = null) {
      (0, _browser.withScope)(scope => {
        if (extras) {
          scope.setExtra('extras', extras);
        }

        (0, _browser.captureMessage)(message);
      });
    }

    reportToAnalytics(event) {
      this.metrics.trackEvent({
        category: event.category,
        action: event.action,
        label: event.label,
        value: event.value
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "metrics", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = AppLoggerService;
});
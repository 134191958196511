define("tortuga-frontend/validations/checkbox-validator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = checkboxValidator;

  function checkboxValidator(opts) {
    return (key, newValue
    /*, oldValue, changes, content*/
    ) => {
      if (newValue === true) {
        return true;
      }

      return opts.message;
    };
  }
});
define("tortuga-frontend/services/order-state", ["exports", "ember-local-storage", "tortuga-frontend/models/order-line-item"], function (_exports, _emberLocalStorage, _orderLineItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and set to use loose mode. ' + 'To use proposal-class-properties in spec mode with decorators, wait for ' + 'the next major version of decorators in stage 2.'); }

  let OrderStateService = (_dec = (0, _emberLocalStorage.storageFor)('current-order'), _dec2 = Ember.computed.alias('currentOrder.order'), _dec3 = Ember.computed('cart.items.[]'), _dec4 = Ember.computed.alias('hasCartItems'), _dec5 = Ember.computed('customer'), _dec6 = Ember.computed('order'), _dec7 = Ember.computed('orderItems.[]'), _dec8 = Ember.computed('totalPrice'), _dec9 = Ember.computed('cart.orderedItems.[]', 'products.products.[]'), (_class = (_temp = class OrderStateService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "currentOrder", _descriptor, this);

      _initializerDefineProperty(this, "cart", _descriptor2, this);

      _initializerDefineProperty(this, "products", _descriptor3, this);

      _initializerDefineProperty(this, "store", _descriptor4, this);

      this.customer = null;

      _initializerDefineProperty(this, "order", _descriptor5, this);

      _initializerDefineProperty(this, "isReadyForCustomerDetails", _descriptor6, this);
    }

    // flags
    get hasCartItems() {
      return this.cart.items.length;
    }

    get hasIdentityVerified() {
      return this.customer !== null;
    }

    get orderHasBeenMade() {
      return this.order !== null;
    }

    get totalPrice() {
      return this.orderItems.reduce((acc, orderItem) => {
        acc += orderItem.totalPrice;
        return acc;
      }, 0);
    }

    get formattedTotalPrice() {
      // TODO: currency etc based on locale
      return (this.totalPrice / 100).toLocaleString('cs-CZ', {
        style: 'currency',
        currency: 'CZK',
        minimumFractionDigits: 0
      });
    }

    get orderItems() {
      const products = this.products.products;
      return this.cart.orderedItems.map(item => {
        let productInCart = products.filter(product => {
          return product.variations.filter(variation => variation.id === item.productVariationId).length;
        });

        if (!productInCart.length) {
          // TODO: report error, filter this item out, handle empty cart if that happens
          throw new Error(`CartItem with productVariationId: ${item.productVariationId} not found in products of length ${products.length}`);
        }

        productInCart = productInCart[0];
        const variation = productInCart.variations.filter(variation => variation.id === item.productVariationId)[0];
        let orderItem = new _orderLineItem.default(variation.id, variation.title, productInCart.title, variation.price, item.quantity);
        return orderItem;
      });
    }

    updateCustomer(customer) {
      this.set('customer', customer);
    }

    resetCustomer() {
      this.set('customer', null);
    }

    updateOrder(order) {
      this.currentOrder.set('order', order);
    }

    resetOrder() {
      this.currentOrder.clear();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentOrder", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "cart", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "products", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "store", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "order", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "hasCartItems", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "hasCartItems"), _class.prototype), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "isReadyForCustomerDetails", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "hasIdentityVerified", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "hasIdentityVerified"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "orderHasBeenMade", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "orderHasBeenMade"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "totalPrice", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "totalPrice"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "formattedTotalPrice", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "formattedTotalPrice"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "orderItems", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "orderItems"), _class.prototype)), _class));
  _exports.default = OrderStateService;
});
define("tortuga-frontend/components/header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class HeaderComponent extends Ember.Component {
    constructor(...args) {
      super(...args);
      this.tagName = 'header';
      this.classNames = ['header'];
    }

  }

  _exports.default = HeaderComponent;
});
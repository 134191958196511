define("tortuga-frontend/components/order-form", ["exports", "ember-concurrency", "ember-changeset-validations", "ember-changeset", "tortuga-frontend/validations/order-options-validation", "tortuga-frontend/validations/name-validation", "tortuga-frontend/events/order-attempted", "tortuga-frontend/events/order-created", "tortuga-frontend/events/customer-verification-done", "tortuga-frontend/events/customer-verification-discarded"], function (_exports, _emberConcurrency, _emberChangesetValidations, _emberChangeset, _orderOptionsValidation, _nameValidation, _orderAttempted, _orderCreated, _customerVerificationDone, _customerVerificationDiscarded) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and set to use loose mode. ' + 'To use proposal-class-properties in spec mode with decorators, wait for ' + 'the next major version of decorators in stage 2.'); }

  let OrderFormComponent = (_dec = Ember.computed('availableSlots.[]'), _dec2 = Ember.computed('identityVerified', 'submitForm.isRunning', 'changesetOptions.isValid', 'orderState.hasCartItems'), _dec3 = Ember.computed('changesetName.isValid', 'orderState.hasCartItems', 'verifyCustomer.isRunning'), _dec4 = Ember.computed('orderState.orderItems'), _dec5 = Ember.computed.alias('orderState.hasIdentityVerified'), _dec6 = Ember.computed.alias('orderState.customer.isFacebookLoginCustomer'), _dec7 = Ember.computed.alias('orderState.customer.isMobileCustomer'), _dec8 = Ember.computed.alias('changesetOptions.orderTime'), _dec9 = Ember.computed.alias('changesetName.name'), _dec10 = (0, _emberConcurrency.task)(function* (registrationType, accountKitCode) {
    try {
      yield this.customerManager.verifyCustomerViaAccountKit(registrationType, accountKitCode, this.changesetName.get('name'));
      this.appLogger.reportToAnalytics(new _customerVerificationDone.default('mobile'));
    } catch (reason) {
      let serverError = null;

      if (reason.errors && reason.errors.length) {
        serverError = JSON.stringify(reason.errors[0]);
      }

      this.appLogger.error(reason, true, serverError);
      this.flashMessages.danger(`Nepodařilo se ověření :( Zkuste to prosím znovu. Pokud problém přetrvává, dejte nám prosím vědět, až se u nás příště zastavíte.`);
    }
  }).drop(), _dec11 = (0, _emberConcurrency.task)(function* () {
    this.changesetOptions.save();
    const customer = this.orderState.customer; // recreate correct UTC slot datetime

    const orderTime = this.slots.findSlot(this.changesetOptions.get('orderTime')).slot; // TODO: currency based on locale

    const order = this.store.createRecord('order', {
      customer,
      delivery_type: 'pickup',
      payment_type: 'cash',
      order_time: orderTime,
      is_takeaway: this.changesetOptions.get('orderTakeaway') * 1
    });
    const orderItems = this.orderState.orderItems.map(orderLineItem => {
      const orderItem = this.store.createRecord('order-item', {
        order,
        product_variation_id: orderLineItem.variationId,
        quantity: orderLineItem.quantity
      });
      return orderItem;
    });
    order.items.pushObjects(orderItems);
    this.appLogger.reportToAnalytics(new _orderAttempted.default(this.orderState.totalPrice / 100));

    try {
      const savedOrder = yield order.save();
      this.orderState.updateOrder(savedOrder);
      this.cart.resetCart();

      this._scrollToTop();

      this.flashMessages.clearMessages();
      this.appLogger.reportToAnalytics(new _orderCreated.default(order.total_amount / 100));
      this.router.transitionTo('confirmation');
    } catch (reason) {
      if (reason.errors.length && reason.errors[0].status === 409) {
        this.flashMessages.danger(`Je nám líto, ale vybraný čas se mezitím už zaplnil :( Vyberte prosím nový před odesláním objednávky.`);
        this.slots.reloadSlots();
        this.appLogger.log('Order not saved - no slots.', JSON.stringify(reason.errors[0]));
        return;
      }

      let serverError = null;

      if (reason.errors && reason.errors.length) {
        serverError = JSON.stringify(reason.errors[0]);
      }

      this.appLogger.error(reason, true, serverError);
      this.flashMessages.danger(`Nepodařilo se dokončit objednávku :( Zkuste to prosím znovu. Pokud problém přetrvává, dejte nám prosím vědět, až se u nás příště zastavíte.`);
    }
  }).drop(), (_class = (_temp = class OrderFormComponent extends Ember.Component {
    constructor() {
      super(...arguments); // default validation is against mobile customer

      _initializerDefineProperty(this, "appLogger", _descriptor, this);

      _initializerDefineProperty(this, "cart", _descriptor2, this);

      _initializerDefineProperty(this, "customerManager", _descriptor3, this);

      _initializerDefineProperty(this, "facebookLogin", _descriptor4, this);

      _initializerDefineProperty(this, "flashMessages", _descriptor5, this);

      _initializerDefineProperty(this, "kitchenState", _descriptor6, this);

      _initializerDefineProperty(this, "orderState", _descriptor7, this);

      _initializerDefineProperty(this, "router", _descriptor8, this);

      _initializerDefineProperty(this, "slots", _descriptor9, this);

      _initializerDefineProperty(this, "store", _descriptor10, this);

      this.classNames = ['order-form'];
      this.availableSlots = [];
      this.yesNoOptions = [{
        value: '1',
        label: 'Ano'
      }, {
        value: '0',
        label: 'Ne'
      }];

      _initializerDefineProperty(this, "identityVerified", _descriptor11, this);

      _initializerDefineProperty(this, "isVerifiedViaFacebook", _descriptor12, this);

      _initializerDefineProperty(this, "isVerifiedViaMobile", _descriptor13, this);

      _initializerDefineProperty(this, "isOrderTimePicked", _descriptor14, this);

      _initializerDefineProperty(this, "isNameFilled", _descriptor15, this);

      _initializerDefineProperty(this, "verifyCustomer", _descriptor16, this);

      _initializerDefineProperty(this, "submitForm", _descriptor17, this);

      this._setVerificationType('mobile');
    }

    didInsertElement() {
      super.didInsertElement(...arguments);
      this.changesetOptions.validate();
      this.changesetName.validate();
    }

    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments); // when available slots change, check whether time
      // has been picked before -> alert customer

      if (this.changesetOptions.get('orderTime')) {
        this.changesetOptions.set('orderTime', null);
        this.changesetOptions.validate();
      }
    }

    get timeSlots() {
      return this.availableSlots.map(slot => {
        return {
          value: slot.id,
          label: slot.title
        };
      });
    }

    get isSubmitDisabled() {
      // submitting or no cart items
      if (this.submitForm.isRunning || !this.orderState.hasCartItems) {
        return true;
      } // customer verified and pickup time filled - green light


      if (this.identityVerified && this.changesetOptions.isValid) {
        return false;
      } // fallback


      return true;
    }

    get isIdentityVerificationDisabled() {
      return !this.orderState.hasCartItems || !this.changesetName.isValid || this.verifyCustomer.isRunning;
    }

    get orderItems() {
      return this.orderState.orderItems;
    }

    _scrollToTop() {
      document.getElementById('header').scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest'
      });
    }

    _setVerificationType(verificationType) {
      // mobile - requires pickup time and name
      if (verificationType === 'mobile') {
        this.set('changesetOptions', new _emberChangeset.default({
          orderTime: '',
          orderTakeaway: '',
          tosAgreement: false
        }, (0, _emberChangesetValidations.default)(_orderOptionsValidation.default), _orderOptionsValidation.default));
        this.set('changesetName', new _emberChangeset.default({
          name: this.identityVerified ? this.orderState.customer.name : null
        }, (0, _emberChangesetValidations.default)(_nameValidation.default), _nameValidation.default));
        this.changesetOptions.validate();
        this.changesetName.validate();
      }
    }

    resetCustomer() {
      this.appLogger.reportToAnalytics(new _customerVerificationDiscarded.default(this.orderState.customer.reg_type));
      this.customerManager.resetCustomer();
    }

    verifyAccountKitCustomer(registrationType, accountKitCode) {
      this.verifyCustomer.perform(registrationType, accountKitCode);
    }

    submitOrder() {
      this.submitForm.perform();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "appLogger", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "cart", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "customerManager", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "facebookLogin", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "flashMessages", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "kitchenState", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "orderState", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "router", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "slots", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "store", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "timeSlots", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "timeSlots"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isSubmitDisabled", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "isSubmitDisabled"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isIdentityVerificationDisabled", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "isIdentityVerificationDisabled"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "orderItems", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "orderItems"), _class.prototype), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "identityVerified", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "isVerifiedViaFacebook", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "isVerifiedViaMobile", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "isOrderTimePicked", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "isNameFilled", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "resetCustomer", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "resetCustomer"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "verifyAccountKitCustomer", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "verifyAccountKitCustomer"), _class.prototype), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "verifyCustomer", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "submitForm", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "submitOrder", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "submitOrder"), _class.prototype)), _class));
  _exports.default = OrderFormComponent;
});
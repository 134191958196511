define("tortuga-frontend/templates/components/header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kEaDqDXs",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"id\",\"header\"],[11,\"class\",\"header__container\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"header__image-wrapper\"],[9],[0,\"\\n    \"],[7,\"img\"],[11,\"src\",\"/assets/images/header.png\"],[11,\"alt\",\"\"],[11,\"class\",\"header__image header__image--desktop\"],[9],[10],[0,\"\\n    \"],[7,\"img\"],[11,\"src\",\"/assets/images/mobile_header.png\"],[11,\"alt\",\"\"],[11,\"class\",\"header__image header__image--mobile\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"h1\"],[11,\"class\",\"title is-2 is-sr-only\"],[9],[0,\"\\n    Tortuga Bay - Bar & Grill\\n  \"],[10],[0,\"\\n  \"],[7,\"h2\"],[11,\"class\",\"subtitle is-4 is-sr-only\"],[9],[0,\"\\n    Best burgerrrs in town, aye.\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tortuga-frontend/templates/components/header.hbs"
    }
  });

  _exports.default = _default;
});
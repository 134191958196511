define("tortuga-frontend/templates/components/message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xq25+eW1",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"message-header\"],[9],[0,\"\\n  \"],[7,\"p\"],[9],[1,[23,\"title\"],false],[10],[0,\"\\n\"],[4,\"if\",[[25,[\"close\"]]],null,{\"statements\":[[0,\"    \"],[7,\"button\"],[11,\"class\",\"delete\"],[11,\"aria-label\",\"delete\"],[9],[3,\"action\",[[24,0,[]],[25,[\"close\"]]]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"message-body\"],[9],[0,\"\\n  \"],[2,\" template-lint triple-curlies=false \"],[0,\"\\n  \"],[1,[23,\"formattedBody\"],true],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tortuga-frontend/templates/components/message.hbs"
    }
  });

  _exports.default = _default;
});
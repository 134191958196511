define("tortuga-frontend/templates/components/product-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OeW3ALyu",
    "block": "{\"symbols\":[\"variation\"],\"statements\":[[7,\"div\"],[11,\"class\",\"card\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"card-content\"],[9],[0,\"\\n\"],[4,\"unless\",[[25,[\"hideHeading\"]]],null,{\"statements\":[[0,\"      \"],[7,\"article\"],[12,\"class\",[30,[\"media product-card__heading \",[29,\"unless\",[[25,[\"product\",\"description\"]],\"product-card__heading--short\"],null]]]],[9],[0,\"\\n\"],[0,\"        \"],[7,\"div\"],[11,\"class\",\"media-content\"],[9],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"content\"],[9],[0,\"\\n            \"],[7,\"h4\"],[11,\"class\",\"product-card__title\"],[9],[1,[25,[\"product\",\"title\"]],false],[10],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"product\",\"description\"]]],null,{\"statements\":[[0,\"              \"],[7,\"p\"],[11,\"class\",\"product-card__description\"],[9],[0,\"\\n                \"],[1,[25,[\"product\",\"description\"]],false],[0,\"\\n              \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"each\",[[25,[\"product\",\"variations\"]]],null,{\"statements\":[[0,\"      \"],[5,\"variation-line\",[],[[\"@variation\"],[[24,1,[]]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tortuga-frontend/templates/components/product-card.hbs"
    }
  });

  _exports.default = _default;
});
define("tortuga-frontend/components/message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _temp;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let MessageComponent = (_dec = Ember.computed('body'), _dec2 = Ember.computed('context'), (_class = (_temp = class MessageComponent extends Ember.Component {
    constructor(...args) {
      super(...args);
      this.classNames = ['message'];
      this.classNameBindings = ['contextClass'];
      this.context = null;
      this.title = '';
      this.body = '';
    }

    get formattedBody() {
      return Ember.String.htmlSafe(this.body);
    }

    get contextClass() {
      return `is-${this.context}`;
    }

  }, _temp), (_applyDecoratedDescriptor(_class.prototype, "formattedBody", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "formattedBody"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "contextClass", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "contextClass"), _class.prototype)), _class));
  _exports.default = MessageComponent;
});
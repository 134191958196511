define("tortuga-frontend/components/order-item-line", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and set to use loose mode. ' + 'To use proposal-class-properties in spec mode with decorators, wait for ' + 'the next major version of decorators in stage 2.'); }

  let OrderItemLineComponent = (_class = (_temp = class OrderItemLineComponent extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "cart", _descriptor, this);

      this.classNames = ['variation-line'];
      this.classNameBindings = ['countInCart:list-group-item-success'];
      this.countInCart = false;
    }

    removeFromCart() {
      this.cart.removeFromCart(this.orderItem.variationId);
    }

    addToCart() {
      this.cart.addToCart(this.orderItem.variationId);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "cart", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "removeFromCart", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "removeFromCart"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addToCart", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "addToCart"), _class.prototype)), _class);
  _exports.default = OrderItemLineComponent;
});
define("tortuga-frontend/components/product-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ProductCardComponent extends Ember.Component {
    constructor(...args) {
      super(...args);
      this.classNames = ['product-card'];
      this.hideHeading = false;
    }

  }

  _exports.default = ProductCardComponent;
});
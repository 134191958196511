define("tortuga-frontend/components/buttons/minus-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class MinusButtonComponent extends Ember.Component {
    constructor(...args) {
      super(...args);
      this.tagName = 'button';
      this.classNames = ['minus-button'];
    }

    click() {
      this.onClick();
    }

  }

  _exports.default = MinusButtonComponent;
});
define("tortuga-frontend/templates/components/account-kit-login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RF+GiG2U",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\"],[12,\"class\",[30,[\"button is-primary \",[29,\"if\",[[25,[\"isLoading\"]],\"is-loading\"],null]]]],[12,\"disabled\",[23,\"disabled\"]],[9],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"icon\"],[9],[0,\"\\n    \"],[7,\"i\"],[11,\"class\",\"fab fa-whatsapp\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"span\"],[9],[0,\"Přihlásit přes telefon\"],[10],[0,\"\\n\"],[3,\"action\",[[24,0,[]],\"smsLogin\"],[[\"on\"],[\"click\"]]],[10],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tortuga-frontend/templates/components/account-kit-login.hbs"
    }
  });

  _exports.default = _default;
});
define("tortuga-frontend/sentry", ["exports", "@sentry/browser", "@sentry/integrations/esm/ember", "tortuga-frontend/config/environment"], function (_exports, Sentry, _ember, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.startSentry = startSentry;

  function startSentry() {
    Sentry.init({ ..._environment.default.sentry,
      environment: _environment.default.environment,
      integrations: [new _ember.Ember()],

      beforeSend(event, hint) {
        const error = hint.originalException; // ignore aborted route transitions from the Ember.js router

        if (error && error.name === 'TransitionAborted') {
          return null;
        }

        if (['development', 'test'].includes(event.environment)) {
          console.error(error, event);
          return null;
        }

        return event;
      }

    });
  }
});
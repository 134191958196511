define("tortuga-frontend/services/customer-manager", ["exports", "ember-local-storage"], function (_exports, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and set to use loose mode. ' + 'To use proposal-class-properties in spec mode with decorators, wait for ' + 'the next major version of decorators in stage 2.'); }

  let CustomerManagerService = (_dec = (0, _emberLocalStorage.storageFor)('current-customer'), (_class = (_temp = class CustomerManagerService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "currentCustomer", _descriptor, this);

      _initializerDefineProperty(this, "facebookLogin", _descriptor2, this);

      _initializerDefineProperty(this, "orderState", _descriptor3, this);

      _initializerDefineProperty(this, "store", _descriptor4, this);
    }

    /**
     *
     * @param {string} registrationType email|mobile
     * @param {string} accountKitCode Facebook Account Kit code
     * @param {string} customerName
     * @returns {Promise}
     */
    verifyCustomerViaAccountKit(registrationType, accountKitCode, customerName) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        const customer = this.store.createRecord('customer', {
          reg_type: registrationType,
          code: accountKitCode,
          name: customerName
        });
        customer.save().then(customer => {
          this.orderState.updateCustomer(customer); // save to localStorage for next time
          // TODO: based on RememberMe flag

          this.currentCustomer.set('data', {
            account_id: customer.account_kit_id,
            reg_type: customer.reg_type,
            name: customer.name
          });
          resolve(customer);
        }, reason => {
          reject(reason);
        });
      });
    }
    /**
     *
     * @param {string} accessToken Facebook Login access token
     * @returns {Promise}
     */


    verifyCustomerViaFacebookLogin(accessToken) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        const customer = this.store.createRecord('customer', {
          reg_type: 'facebook',
          code: accessToken
        });
        customer.save().then(customer => {
          this.orderState.updateCustomer(customer);
          resolve(customer);
        }, reason => {
          reject(reason);
        });
      });
    }
    /**
     * @returns {Promise}
     */


    resetCustomer() {
      return new Ember.RSVP.Promise(resolve => {
        const customer = this.orderState.customer;

        if (customer) {
          if (customer.isFacebookLoginCustomer) {
            // log in out of facebook login
            this.facebookLogin.logout();
          } // unload record from store to prevent store error when creating
          // new record (while returning existing record) has the same id


          this.store.unloadRecord(customer);
        }

        this.orderState.resetCustomer();
        resolve();
      });
    }

    initCustomerFromLocalStorage() {
      const customerData = this.currentCustomer.get('data');

      if (!customerData) {
        return;
      }

      this.store.query('customer', customerData).then(customer => {
        if (customer.length == 1) {
          this.orderState.updateCustomer(customer.get('firstObject'));
        }
      }, () => {
        // clear incorrect data
        this.currentCustomer.clear();
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentCustomer", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "facebookLogin", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "orderState", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "store", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = CustomerManagerService;
});
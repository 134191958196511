define("tortuga-frontend/templates/catchall", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IfKiXfZ5",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\"],[11,\"class\",\"hero is-fullheight\"],[9],[0,\"\\n  \"],[5,\"header\",[],[[],[]]],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"index-container\"],[9],[0,\"\\n    \"],[5,\"message\",[],[[\"@title\",\"@body\",\"@context\"],[\"Stránka nenalezena.\",\"Takovou stránku tu nemáme. Nevadí! Pokračujte na hlavní stránku checknout naši famózní nabídku 🏴‍☠️\",\"dark\"]]],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"has-text-centered\"],[9],[0,\"\\n      \"],[5,\"link-to\",[[13,\"class\",\"button is-primary is-large\"]],[[\"@route\"],[\"index\"]],{\"statements\":[[0,\"\\n        Pokračovat na hlavní stranu\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"footer\"],[11,\"class\",\"footer\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"content has-text-centered\"],[9],[0,\"\\n      \"],[7,\"p\"],[9],[0,\"\\n        \"],[7,\"strong\"],[9],[0,\"© 2019 Tortuga Bay - Bar & Grill\"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tortuga-frontend/templates/catchall.hbs"
    }
  });

  _exports.default = _default;
});
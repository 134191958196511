define("tortuga-frontend/templates/components/form/form-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ep2Ru2EY",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"field-label is-normal\"],[9],[0,\"\\n  \"],[7,\"label\"],[11,\"class\",\"label\"],[12,\"for\",[30,[\"input-\",[23,\"name\"],\"-\",[25,[\"element\",\"id\"]]]]],[9],[1,[23,\"label\"],false],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"field-body\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"field\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"control\"],[9],[0,\"\\n      \"],[7,\"input\"],[12,\"class\",[30,[\"input \",[29,\"if\",[[25,[\"hasError\"]],\"is-danger\"],null],\" \",[29,\"if\",[[25,[\"isValid\"]],\"is-success\"],null]]]],[12,\"id\",[30,[\"input-\",[23,\"name\"],\"-\",[25,[\"element\",\"id\"]]]]],[12,\"name\",[23,\"name\"]],[12,\"placeholder\",[23,\"placeholder\"]],[12,\"value\",[29,\"changeset-get\",[[25,[\"changeset\"]],[25,[\"name\"]]],null]],[12,\"oninput\",[29,\"action\",[[24,0,[]],[29,\"changeset-set\",[[25,[\"changeset\"]],[25,[\"name\"]]],null]],[[\"value\"],[\"target.value\"]]]],[12,\"disabled\",[23,\"disabled\"]],[12,\"type\",[23,\"inputType\"]],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"hasError\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"help is-danger\"],[9],[1,[23,\"errorMessage\"],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[25,[\"isValid\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"help is-success\"],[9],[0,\"V pořádku!\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tortuga-frontend/templates/components/form/form-input.hbs"
    }
  });

  _exports.default = _default;
});
define("tortuga-frontend/services/facebook-login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and set to use loose mode. ' + 'To use proposal-class-properties in spec mode with decorators, wait for ' + 'the next major version of decorators in stage 2.'); }

  let FacebookLoginService = (_class = (_temp = class FacebookLoginService extends Ember.Service {
    constructor() {
      super(...arguments); // HACK: not used

      _initializerDefineProperty(this, "customerManager", _descriptor, this);

      return; // window.fbAsyncInit = () => {
      //     FB.init({
      //         appId: config.facebookLogin.appId,
      //         version: config.facebookLogin.version,
      //         cookie: true,
      //         xfbml: true,
      //     });
      //     FB.AppEvents.logPageView();
      //     // instantly load customer if it exists
      //     this.checkStatus().then(accessToken => {
      //         this.customerManager.verifyCustomerViaFacebookLogin(accessToken).catch(reason => {
      //             // TODO: error reporting
      //             console.error('verifyCustomerViaFacebookLogin error', reason);
      //         });
      //     });
      // };
    }
    /**
     * Check whether user is logged in and resolve with accessToken if they are "connected"
     * @returns {Promise}
     */


    checkStatus() {
      return new Ember.RSVP.Promise(resolve => {
        FB.getLoginStatus(response => {
          if (response.status !== 'connected') {
            return;
          }

          resolve(response.authResponse.accessToken);
        });
      });
    }
    /**
     * Log user in and resolve with accessToken or reject with a reason
     * @returns {Promise}
     */


    login() {
      return new Ember.RSVP.Promise((resolve, reject) => {
        FB.login(response => {
          if (!response.authResponse) {
            return reject('User cancelled login or did not fully authorize.');
          }

          resolve(response.authResponse.accessToken);
        }, {
          scope: 'email,user_link',
          auth_type: 'rerequest'
        });
      });
    }
    /**
     * Log user out
     * @returns {void}
     */


    logout() {
      FB.logout();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "customerManager", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = FacebookLoginService;
});
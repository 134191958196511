define("tortuga-frontend/events/customer-verification-done", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class CustomerVerificationDoneEvent {
    constructor(method = '') {
      this.category = 'customer';
      this.action = 'verification_done';
      this.label = method;
      this.value = 0;
    }

  }

  _exports.default = CustomerVerificationDoneEvent;
});
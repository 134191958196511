define("tortuga-frontend/validations/order-options-validation", ["exports", "ember-changeset-validations/validators", "tortuga-frontend/validations/checkbox-validator"], function (_exports, _validators, _checkboxValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    orderTime: [(0, _validators.validatePresence)({
      presence: true,
      message: 'Vyberte čas vyzvednutí.'
    })],
    tosAgreement: (0, _checkboxValidator.default)({
      message: 'Musíte souhlasit s podmínkami služby.'
    }),
    orderTakeaway: [(0, _validators.validatePresence)({
      presence: true,
      message: 'Vyberte zda-li chcete jídlo s sebou nebo ne.'
    })]
  };
  _exports.default = _default;
});
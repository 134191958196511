define("tortuga-frontend/models/order-line-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let OrderLineItem = (_dec = Ember.computed('variationTitle'), _dec2 = Ember.computed('variationPrice', 'quantity'), _dec3 = Ember.computed('totalPrice'), (_class = class OrderLineItem {
    constructor(variationId, variationTitle, productTitle, variationPrice, quantity) {
      this.variationId = variationId;
      this.variationTitle = variationTitle;
      this.productTitle = productTitle;
      this.variationPrice = parseInt(variationPrice, 10);
      this.quantity = parseInt(quantity, 10);
      Object.freeze(this);
    }

    get title() {
      return `${this.variationTitle}`;
    }

    get totalPrice() {
      return this.variationPrice * this.quantity;
    }

    get formattedTotalPrice() {
      return (this.totalPrice / 100).toLocaleString('cs-CZ', {
        style: 'currency',
        currency: 'CZK',
        minimumFractionDigits: 0
      });
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "title", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "title"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "totalPrice", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "totalPrice"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "formattedTotalPrice", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "formattedTotalPrice"), _class.prototype)), _class));
  var _default = OrderLineItem;
  _exports.default = _default;
});